/*eslint-disable camelcase*/

import React, { Fragment } from 'react';
import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import { bpProps } from 'utils/responsiveUtils';
import { useSelector, useDispatch } from 'react-redux';
// import sanitizeHtml from 'sanitize-html';
import { Link } from 'react-router-dom';
import { setActiveModal } from 'redux/actions';
import LocalizationModal from 'components/LocalizationModal';
import { ModalConductor, Icon } from '@springforcreators/propel-ui';
import tracker from 'utils/tracking';
import SocialMediaIcons from 'components/SocialMediaIcons';
import HubspotForm from 'react-hubspot-form';
import footerTemplates from './footerTemplates';
import './Footer.scss';

export const Footer = () => {
  const dispatch = useDispatch();

  const { themeData, localizationData, activeModal } = useSelector(
    state => state
  );
  const { getStyles } = useSelector(state => ({ ...bpProps(state) }));
  const {
    brand, content, styles/* , meta */
  } = themeData;
  /* const {
    storeName
  } = meta; */

  const { buyer_currency, available_currencies, fulfillment_region } =
    localizationData;

  const currency = available_currencies.find(
    cur => cur.currency === buyer_currency
  );

  const customizer = (objValue, srcValue) => {
    if (isArray(objValue)) return srcValue;
    return undefined;
  };

  const templateStyles = footerTemplates[get(styles, 'footer.template')];
  const baseFooterStyles = get(styles, 'footer');

  // Merge footer template styles & custom styles into single object
  const footerStyles = mergeWith(templateStyles, baseFooterStyles, customizer);

  const showFooterAboutSection =
    get(content, 'footer.about.textContent') ||
    (get(content, 'footer.showSocialIcons') && get(brand, 'socialMedia'));

  let infoLinks = [
    {
      url: 'https://sprisupport.zendesk.com/hc/en-us/requests/new',
      external: true,
      onClick: () => tracker.track('footer.support_links.clicked'),
      label: 'Support',
      key: 'support_links'
    },
    {
      url: 'https://teespring.com/track/?utm_source=store&utm_medium=footer&utm_campaign=track_order_link',
      external: true,
      onClick: () => tracker.track('footer.track_order.clicked'),
      label: 'Track Order',
      key: 'track_order'
    },
    {
      url: 'https://sprisupport.zendesk.com/hc/en-us/requests/new',
      external: true,
      onClick: () => tracker.track('footer.contact_link.clicked'),
      label: 'Contact Us',
      key: 'contact_link'
    },
    {
      url: 'https://sprisupport.zendesk.com/hc/en-us/articles/12171053036685-Returns-And-Cancellations',
      external: true,
      onClick: () => tracker.track('footer.refund_policy_link.clicked'),
      label: 'Refund Policy',
      key: 'refund_policy_link'
    }
  ];

  if (process.env.REACT_APP_SHOW_SUPPORT) {
    infoLinks = [
      {
        url: '/support',
        external: false,
        onClick: () => tracker.track('footer.support_link.clicked'),
        label: 'Support',
        key: 'support_link'
      },
      {
        url: '/track',
        external: false,
        onClick: () => tracker.track('footer.track_order.clicked'),
        label: 'Track order',
        key: 'track_order'
      },
      {
        url: '/contact',
        external: false,
        onClick: () => tracker.track('footer.contact_link.clicked'),
        label: 'Contact Us',
        key: 'contact_link'
      }
    ];
  }

  return (
    <Fragment>
      <ModalConductor
        activeModal={ activeModal }
        setActiveModal={ state => dispatch(setActiveModal(state)) }
        modals={ [
          {
            id: 'localization-modal',
            header: 'Preferences',
            className: 'localizationmodal',
            node: <LocalizationModal />
          }
        ] }
      />

      <footer
        className={ `footer universal ${get(footerStyles, 'className') || ''}` }
        style={ {
          ...getStyles('bgStyles', footerStyles),
          ...getStyles('textStyles', footerStyles)
        } }
      >
        <div
          className="footer__inner"
          style={ getStyles('container', footerStyles) }
        >

          { showFooterAboutSection && (
            <div className="footer__about" style={ getStyles('footerAboutStyles', footerStyles) }>

              {/* { (get(content, 'footer.about.textContent') && (
                <div
                  className="mb2 footer__about-text"
                  dangerouslySetInnerHTML={ {
                    __html: sanitizeHtml(
                      get(content, 'footer.about.textContent')
                    )
                  } }
                />
              )) } */}

              {get(content, 'footer.about.showReadMore') && (
                <Link className="footer__readmore" to="/about">
                  Read more
                </Link>
              )}

              {get(content, 'footer.showSocialIcons') &&
                get(brand, 'socialMedia') && (
                <div className="footer__socialicons">
                  <SocialMediaIcons
                    styles={ getStyles('socialStyles', footerStyles) }
                  />
                </div>
              )}
            </div>
          )}

          <div className="footer__menu">
            <ul style={ getStyles('menuStyles', footerStyles) }>
              {infoLinks
                .filter(
                  link => link.condition === undefined || link.condition === true
                )
                .map((link) => {
                  const linkElem = link.external ? (
                    <a href={ link.url } onClick={ link.onClick }>
                      {link.label}
                    </a>
                  ) : (
                    <Link to={ link.url } onClick={ link.onClick }>
                      {link.label}
                    </Link>
                  );

                  return <li key={ link.key }>{linkElem}</li>;
                })}
            </ul>
          </div>
          <div className="footer__newsletter" style={ getStyles('newsletterStyles', footerStyles) }>
            <h5>GET NOTIFIED</h5>
            <p className="footer__newsletter__subtext">
              { get(content, 'footer.newsletterText') || 'Add your email address to stay in the know.' }
            </p>
            <HubspotForm
              portalId="23573381"
              formId="0c1dbc96-55a0-4f1e-8c36-cb90dae1ad56"
              loading={ <div>Loading...</div> }
            />
          </div>
        </div>
        <div className="footer__bottom">
          <div className="footer__bottom__inner">
            <div className="footer__bottomleft">
              <div className="footer__copyright">
                {get(content, 'footer.copyright') && (
                  <span className="mr2">
                    {get(content, 'footer.copyright')}
                  </span>
                )}
                <ul>
                  <li className="mr2">
                    <Link to="/terms-of-use">Terms of use</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer__bottomright" style={ { textAlign: 'center' } }>
              <span>Tommy Innit © 2023. All Rights Reserved, Miami, FL</span>
              <div className="footer__poweredby">
                <a href="https://www.spri.ng/" target="_blank" rel="noopener noreferrer">Powered by Amaze</a>
              </div>
            </div>

            <div className="footer__localization">
              <span className="mr2">Shipping region:</span>
              <button
                type="button"
                className="localizationcontrols"
                onClick={ () => dispatch(setActiveModal('localization-modal')) }
              >
                <span>
                  {fulfillment_region}
                  <Icon name="ChevronDown" className="mx1" size={ 8 } />
                </span>
                <span>
                  {`${currency?.symbol} ${currency?.currency}`}
                  <Icon name="ChevronDown" className="mx1" size={ 8 } />
                </span>
                {/* TODO Disable language selector until functionality is build */}
                {/* <span>
                  { language.local_name }
                  <Icon name='ChevronDown' size={12} />
                </span> */}
              </button>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
